@import "../user-variables";

.red-color {
  color: $primary !important;
}

.black-color {
  color: $black !important;
}

.gray-color {
  color: $gray-600;
}

.dark-red-color {
  color: $dark-red !important;
}

.dark-green-color {
  color: $dark-green !important;
}

.blue-color {
  color: $blue-900;
}

.gray-700-color {
  color: $gray-700;
}

.selected-to-edit {
  background-color: $light;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.whitespace-break-spaces {
  white-space: break-spaces;
}

.break-normal {
  word-break: normal;
}

.pointer-events-none {
  pointer-events: none;
}

.max-w-fit {
  max-width: fit-content !important;
}

.max-h-fit {
  max-height: fit-content !important;
}

.w-fit {
  width: fit-content;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.border-radius-sm {
  border-radius: $border-radius-sm;
}

.fw-500 {
  font-weight: 500;
}

.select-none {
  user-select: none;
}

.select-text {
  user-select: text;
}

.select-all {
  user-select: all;
}

.overflow-unset {
  overflow: unset !important;
}

.popover-wp {
  padding: 0.5rem;
  .popover-body {
    color: $black;
  }
}

.operated-by-popover {
  min-width: fit-content;
}

.new-price {
  color: $orange !important;
}

.flight-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.close-icon {
  padding: 0 !important;
}

.remark-name {
  text-transform: uppercase;
  color: #790003;
}

.h3-label {
  color: #4c5d75;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.h4-label {
  color: #4c5d75;
  font-size: 1rem;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.date-input-control {
  border-radius: $border-radius 0 0 $border-radius;
  border: 1px solid #D8DDEC !important;
  border-right: none !important;
}

.inner-icon {
  display: flex;
  align-items: center;
  border-radius: 0 $border-radius $border-radius 0;
  background-color: white;
  border: 1px solid #D8DDEC;
  border-left: none;
  padding: 0 0.5rem;
}

.exclamation-icon {
  font-size: 18px;
  border-radius: $border-radius-sm;

  &.warning {
    background-color: #ffe69b;
    color: #653700;
  }

  &.problem {
    background-color: $pink;
    color: #311616;

    &.hovered {
      &:hover {
        background-color: #ffa4a6;
      }
    }
  }
}

.custom-tabs {
  display: flex;
  flex-direction: column;

  .tabs {
    .tab {
      cursor: pointer;
      padding: 10px 20px;
      display: inline-block;
      font-size: 1rem;
      color: $gray-800;
      border-radius: $border-radius $border-radius 0px 0px;

      &.active {
        position: relative;
        z-index: 1000;
        border: 1px solid #d1d1d1;
        border-bottom: none;
        bottom: -1px;
        background: $gray-300;
        font-weight: 500;
      }
    }
  }

  .panel {
    border: 1px solid #d1d1d1;
    border-radius: $border-radius;
    overflow: hidden;
    padding: 20px;
    min-height: 100px;
  }
}

@for $i from 0 through 99 {
  .h-#{$i} {
    height: #{$i}px;
  }

  .w-#{$i} {
    width: #{$i}px;
  }

  .mh-#{$i} {
    min-height: #{$i}px !important;
  }

  .mw-#{$i} {
    min-width: #{$i}px;
  }

  .lh-#{$i} {
    line-height: #{$i}px;
  }

  .fs-#{$i} {
    font-size: #{$i}px !important;
  }

  .gap-#{$i} {
    gap: #{$i}px;
  }

  .gap-x-#{$i} {
    column-gap: #{$i}px;
  }

  .gap-y-#{$i} {
    row-gap: #{$i}px;
  }

  .opacity-#{$i} {
    opacity: $i / 100;
  }
}


.expand-icon-wrapper {
  display: flex;
  border: 1px solid $gray-600;
  color: $dark-red;
  border-radius: $border-radius;

  &:hover {
    background-color: #ffbdbf;
  }
}

.sort-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  color: black;
  border-color: grey;
  border-radius: 0;
  font-weight: 500;
  i {
    font-size: 18px;
  }
}

// timeline
.timeline {
  width: 100%;
  position: relative;
  color: black;

  $gutter: 25px;
  $border-width: 2px;

  &.line {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 18%;
      bottom: 0;
      width: $border-width;
      background: #ddd;
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .entry {
    display: flex;
    align-items: flex-start;

    .title {
      flex: 1;
      position: relative;
      .date {
        font-size: 0.9rem;
      }

    }
    .info {
      width: 82%;
      padding-left: $gutter;
    }
  }
}

@media (max-width: 1200px) {
  .timeline {
    &.line {
      &:before {
        left: 22%;
      }
    }
    .entry {
      .info {
        width: 78%;
      }
    }
  }
}

@media (max-width: 992px) {
  .timeline {
    &.line {
      &:before {
        left: 28%;
      }
    }
    .entry {
      .info {
        width: 72%;
      }
    }
  }
}

.travelers-count {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;
  border: 1px solid $gray-300;
  background-color: $gray-300;
  border-radius: $border-radius-sm;
  font-weight: 500;
  height: 24px;
  width: 24px;
  line-height: 1;
}

.info-card {
  border: 1px solid $gray-200;
  border-radius: $border-radius;

  &-body {
    display: flex;

    .blue-container {
      width: 24px;
      background-color: $gray-300;
    }
    .info-container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: .5rem 0;
      margin-left: 10px;
      font-size: $font-size-lg;
    }
  }
}
