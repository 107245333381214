//
// user.scss
// Use this to write your custom SCSS
//

.navbar-brand {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
}

.card, .modal-content {
  label {
    color: #404040;
    margin-bottom: 0.3rem;
  }

  .form-check {
    margin: 0.5rem 0;

    &-label {
      font-size: 1rem;
    }
  }

  .form-group-xs {
    margin-bottom: 1rem;
  }

  .form-group-sm {
    margin-bottom: 1.5rem;
  }

  .form-group {
    margin-bottom: 2rem;
  }

  .form-group-xl {
    margin-bottom: 2.5rem;
  }
}

.navbar-nav {
  flex-direction: row !important;

  .nav-link {
    color: #404040 !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 2;

    &:hover {
      color: #a70004 !important;
    }
  }
}

.nav-tabs {
  .nav-link {
    &.active {
      font-weight: 600;
    }
  }
}

.card {
  &.offering {
    .card-header {
      font-size: 1rem;
    }

    h4 {
      font-weight: 400;
      border-bottom: none;
      margin-bottom: 0.25rem;
      margin-top: 1rem;
    }

    p,
    ul {
      color: #5a5a5a;
      font-size: 0.85rem;
    }

    ul {
      padding-left: 1rem;
    }

    .card-body {
      padding: 1.5rem;
    }

    .flight-detail-group {
      border-bottom: 1px solid $gray-200;
      padding-bottom: 0.5rem;
    }

    .flight-route-detail {
      font-size: $font-size-lg;
    }

    .flight-date {
      font-size: 0.9rem;
      color: #646464;
    }

    .flight-number {
      font-weight: 600;
    }

    .bigger-price,
    .bigger-text {
      font-size: 1.4rem;
      line-height: 1.2;
    }

    .smaller-price,
    .smaller-text {
      font-size: 1rem;
      color: #646464;
    }

    .small-text {
      font-size: 0.9rem;
      color: #646464;
    }
  }
}

.orders-list {
  .pagination {
    margin-bottom: 0;
  }
}

.accordion {
  .card {
    border-color: $gray-500;
  }
  .card:first-of-type {
    border-bottom: 1px solid $gray-500;
  }
}
