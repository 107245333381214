// Badge Mixins
//
// This is a custom mixin for badge-#{color}-soft variant of Bootstrap's .badge class

@mixin badge-variant-soft($bg, $color) {
  color: $color;
  background-color: $bg;

  &[href] {
    @include hover-focus {
      color: $color;
      text-decoration: none;
      background-color: darken($bg, 5%);
    }
  }
}

@mixin ellipsis($width: 100%) {
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin arrow-down-icon {
  content: "";
  font-family: "Feather";
  vertical-align: middle;
}
