.notifier__container * {
  box-sizing: border-box;
}
.notifier__container-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
.notifier__notification {
  display: block;
  position: fixed;
  visibility: hidden;
  z-index: 10000;
  will-change: transform;
  backface-visibility: hidden;
}

.notifier__notification--material {
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  cursor: default;
  padding-top: 11px;
  padding-right: 26px;
  padding-bottom: 10px;
  padding-left: 26px;
}
.notifier__notification--material .notifier__notification-message {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: top;
  line-height: 32px;
  font-size: 15px;
}
.notifier__notification--material .notifier__notification-button {
  display: inline-block;
  transition: opacity 0.2s ease;
  opacity: 0.5;
  margin-right: -10px;
  margin-left: 10px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 6px;
  width: 32px;
  height: 32px;
  vertical-align: top;
}
.notifier__notification--material .notifier__notification-button:hover, .notifier__notification--material .notifier__notification-button:focus {
  opacity: 1;
}
.notifier__notification--material .notifier__notification-button:active {
  transform: scale(0.82);
  opacity: 1;
}

.notifier__notification--default {
  background-color: #444;
  color: #FFF;
}
.notifier__notification--default .notifier__notification-button-icon {
  fill: #FFF;
}

.notifier__notification--error {
  background-color: #D9534F;
  color: #FFF;
}
.notifier__notification--error .notifier__notification-button-icon {
  fill: #FFF;
}

.notifier__notification--info {
  background-color: #5BC0DE;
  color: #FFF;
}
.notifier__notification--info .notifier__notification-button-icon {
  fill: #FFF;
}

.notifier__notification--success {
  background-color: #5CB85C;
  color: #FFF;
}
.notifier__notification--success .notifier__notification-button-icon {
  fill: #FFF;
}

.notifier__notification--warning {
  background-color: #F0AD4E;
  color: #FFF;
}
.notifier__notification--warning .notifier__notification-button-icon {
  fill: #FFF;
}