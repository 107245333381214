.checkbox.custom-checkbox-1 {
  display: inline-block;
  position: relative;
  padding-left: 21px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .checkbox__checkmark:after {
    opacity: 1;
  }

  .checkbox__checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #fff;
    border: 1px solid #8a8a8a;
    transition: background-color 0.25s ease;

    &:after {
      content: "";
      position: absolute;
      left: 4px;
      width: 5px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
      transition: opacity 0.25s ease;
    }
  }

  input:checked ~ .checkbox__checkmark {
    background: #4c4c4c;
  }

  .checkbox__body {
    color: black;
  }
}
