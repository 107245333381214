//
// tables.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

.table {
  vertical-align: middle;

  thead th {
    background-color: $table-head-bg;
    text-transform: uppercase;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    letter-spacing: .08em;
    color: $table-head-color;
    border-bottom-width: $table-border-width;
  }
}

.table-sm {
  font-size: $font-size-sm;

  thead th {
    font-size: $font-size-xs;
  }
}


//
// Dashkit =====================================
//

// No wrap
//
// Prevents table content from wrapping to the next line

.table-nowrap {

  th, td {
    white-space: nowrap;
  }
}


// Sort
//
// Adds sorting icons

.table [data-sort] {

  &::after {
    content: str-replace(url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='#{$text-muted}'/></svg>"), "#", "%23");
    margin-left: .25rem;
  }
}


// Table checkbox

.table-checkbox {
  min-height: 0;
}

.table-checkbox .custom-control-label::before,
.table-checkbox .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
}

.table-default {
  border-collapse: separate;
  border-spacing: 0;

  tr th {
    border-top: 1px solid $gray-200;
  }

  tr th:first-child,
  tr td:first-child {
    border-left: 1px solid $gray-200;
  }

  tr th:last-child,
  tr td:last-child {
    border-right: 1px solid $gray-200;
  }

  /* top-left border-radius */
  tr:first-child th:first-child {
    border-top-left-radius: $border-radius;
  }

  /* top-right border-radius */
  tr:first-child th:last-child {
    border-top-right-radius: $border-radius;
  }

  /* bottom-left border-radius */
  tr:last-child td:first-child {
    border-bottom-left-radius: $border-radius;
  }

  /* bottom-right border-radius */
  tr:last-child td:last-child {
    border-bottom-right-radius: $border-radius;
  }

  th, td {
    &:not(:first-child) {
      text-align: center;
    }
    vertical-align: middle;
  }

  thead {
    th {
      color: #4a4a4a;
      background-color: $gray-300;
      padding: 0.375rem 0.9375rem;
    }
  }

  tbody {
    tr {
      &.expanded {
        td {
          vertical-align: top;
        }
      }
      &:not(.expanded) {
        td {
          vertical-align: middle;
        }
      }
      &:hover {
        .material-symbols-outlined {
          opacity: 1;

          &.remove-icon {
            &:hover {
              color: #9d0208;
            }
          }
        }
      }

      &:last-child {
        td {
          border-bottom: 1px solid $gray-200;
        }
      }

      &.inactive {
        td:not(:last-child) {
          opacity: 0.8;
        }
      }

      td {
        padding: 0.7rem 0.9375rem;;
      }
    }
  }
}

.table-hover {
  tr th:first-child,
  tr td:first-child {
    padding-left: 2rem;
  }

  tr th:last-child,
  tr td:last-child {
    padding-right: 1.5rem;
  }

  tbody {
    tr {
      &:hover {
        background-color: $light;
      }
    }
  }
}

.highlighted-header-table {
  tbody {
    tr {
      td:first-child {
        color: #4a4a4a;
        font-weight: 600;
        text-transform: uppercase;
        background-color: $gray-300;
      }
    }
  }
}
