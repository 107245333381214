//
// popover.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

.popover {
  padding: $popover-padding-y $popover-padding-x;
  border-radius: $border-radius;
  &:hover {
    display: block !important;
  }
}

.popover-header {
  margin-bottom: $popover-header-margin-bottom;
  border-bottom: 0;
}

.popover-body-label {
  margin-left: .25rem;
}

.popover-body-value {
  margin-left: .25rem;
}

.popover-body-indicator {
  display: inline-block;
  width: .5rem; height: .5rem;
  border-radius: 50%;
}

.help-popover {
  padding: 0.5rem;
  min-width: max-content;

  .popover-header {
    text-align: center;
    border-bottom: 1px solid $gray-300;
    padding-bottom: 4px;
    margin-bottom: 0.4rem;
  }

  .popover-body {
    font-size: 14px;
    color: $black;
  }
}
