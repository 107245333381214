//
// user-variables.scss
// Use this to overwrite Bootstrap and Dashkit variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$primary: #CD0005 !default;
$dark: #400000 !default;
$secondary: #BF0000 !default;
$dark-red: #590000 !default;
$dark-green: #07a145 !default;

$info: #E50000 !default;
$success: #7F0000 !default;
$warning: #F0AD4E !default;
$danger: #910002 !default;
$light: #F2F4F7 !default;

$white:    #FFFFFF !default;
$gray-100: #F9FBFD !default;
$gray-200: #E9E9E9 !default;
$gray-300: #E3EBF6 !default;
$gray-400: #D8DDEC !default;
$gray-500: #B1C2D9 !default;
$gray-600: #979797 !default;
$gray-700: #646464 !default;
$gray-800: #3A3A3A !default;
$gray-900: #272727 !default;
$metallic-gray: #c0c0c0 !default;
$black:    #12263F !default;

$blue:       #2C7BE5 !default;
$blue-100:   #F3F8FE !default;
$blue-900:   #4c5d75 !default;
$indigo:     #727cf5 !default; // NOT USED
$purple:     #6b5eae !default;
$pink:       #ffc5c6 !default;
$red:        #E63757 !default;
$orange:     #fd7e14 !default;
$yellow:     #F6C343 !default;
$green:      #00D97E !default;
$green-100:  #E3F3EC !default;
$teal:       #02a8b5 !default; // NOT USED
$cyan:       #39afd1 !default; // NOT USED

$badge-primary: $blue, !default;
$badge-secondary: $gray-700, !default;
$badge-success: $green, !default;
$badge-info: $cyan, !default;
$badge-warning: $yellow, !default;
$badge-danger: $red, !default;
$badge-light: $gray-200, !default;
$badge-dark: $black, !default;

$alert-primary: $blue, !default;
$alert-secondary: $gray-700, !default;
$alert-success: $green, !default;
$alert-info: $cyan, !default;
$alert-warning: $yellow, !default;
$alert-danger-provider: $info, !default;
$alert-danger: $orange, !default;
$alert-light: $gray-200, !default;
$alert-dark: $black, !default;
$alert-problem: $pink, !default;
