/**
 * Dashkit v1.3.1
 *
 * Custom variables followed by Dashkit variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */

// Bootstrap functions
@import '../../../node_modules/bootstrap/scss/functions';

// Custom variables
@import 'user-variables';

// Dashkit variables
@import 'dashkit/variables';

// Bootstrap core
@import '../../../node_modules/bootstrap/scss/bootstrap';

// bsStepper
@import '~bs-stepper/dist/css/bs-stepper.min.css';

// Scrollbar
@import "dashkit/scrollbar";

// Checkboxes
@import "dashkit/checkboxes";

// Dashkit core
@import 'dashkit/dashkit';

// Custom core
@import 'user';
