//
// buttons.scss
// Extended from Bootstrap
//


//
// Dashkit ===================================
//

// Button white

.btn-white {
  border-color: $gray-300;

  @include hover-focus {
    background-color: $gray-100;
    border-color: $gray-400;
  }
}

.btn-group-toggle .btn-white:not(:disabled):not(.disabled):active,
.btn-group-toggle .btn-white:not(:disabled):not(.disabled).active {
  background-color: $input-bg;
  border-color: $input-focus-border-color;
  color: $primary;
}


// Button outline secondary

.btn-outline-secondary {
  &:not(:hover):not([aria-expanded="true"]):not([aria-pressed="true"]){
    border-color: $gray-400;
  }
}


// Button rounded
//
// Creates circle button variations

.btn-rounded-circle {
  width: calc(1em * #{$btn-line-height} + #{$input-btn-padding-y * 2 } + #{$btn-border-width} * 2);
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
}
.btn-rounded-circle.btn-lg {
  width: calc(1em * #{$btn-line-height-lg} + #{$input-btn-padding-y-lg * 2 } + #{$btn-border-width} * 2);
}
.btn-rounded-circle.btn-sm {
  width: calc(1em * #{$btn-line-height-sm} + #{$input-btn-padding-y-sm * 2 } + #{$btn-border-width} * 2);
}


// Button group
//
// Prevent buttons from jittering on hover

.btn-group .btn + .btn {
  margin-left: 0;
}

.btn {
  text-transform: capitalize;
  &:focus {
    box-shadow: none !important;
  }
}

// Custom Button Styles

$white: #FFFFFF;
$brownish-red: #311616;
$grey: #b9b9b9;
$red: #790003;
$pink-red-100: #fedcdd;
$pink-red-200: #ffc5c6;
$pink-red-300: #ffbdbf;

.btn-primary,
.btn-outline-primary,
.btn-tonal-primary,
.btn-secondary,
.btn-success,
.btn-danger {
  border-radius: $border-radius-sm;
  font-weight: 600;
}

.btn-primary {
  &:not(:disabled):not(.disabled) {
    .active,
    &:active,
    &:hover,
    .focus,
    &:focus {
      background-color: $red;
      border-color: $red;
    }
  }
  .disabled,
  &:disabled {
    background-color: $grey;
    border-color: $grey;
  }
}

.btn-outline-primary {
  color: $red;
  border-color: $grey;
  &:not(:disabled):not(.disabled) {
    .active,
    &:active,
    &:hover,
    .focus,
    &:focus {
      color: $red;
      background-color: $pink-red-300;
      border-color: $grey;
    }
  }
  .disabled,
  &:disabled {
    color: $grey;
    background-color: $white;
    border-color: $grey;
  }
}

.btn-tonal-primary {
  color: $brownish-red;
  background-color: $pink-red-100;
  border-color: $pink-red-100;
  &:not(:disabled):not(.disabled) {
    .active,
    &:active,
    &:hover,
    .focus,
    &:focus {
      color: $brownish-red;
      background-color: $pink-red-200;
      border-color: $pink-red-200;
    }
  }
  .disabled,
  &:disabled {
    color: $white;
    background-color: $grey;
    border-color: $grey;
  }
}

.btn-secondary {
  color: $red;
  background-color: transparent;
  border-color: transparent;
  &:not(:disabled):not(.disabled) {
    .active,
    &:active,
    &:hover {
      color: $red;
      background-color: $pink-red-300 !important;
      border-color: $pink-red-300 !important;
    }
  }
  &:not(:disabled):not(.disabled) {
    &:focus,
    .focus {
      color: $red;
      background-color: transparent;
      border-color: transparent;
    }
  }
  .disabled,
  &:disabled {
    color: $grey;
    background-color: transparent;
    border-color: transparent;
  }
}

.show > .btn-outline-primary.dropdown-toggle {
  color: $red;
  background-color: $pink-red-300;
  border-color: $grey;
}
